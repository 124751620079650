.in-hole {
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
  animation-name: in-hole;
}

@keyframes in-hole {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(0);
  }
}
