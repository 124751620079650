.ch-container{display:block}.ch-container,.ch-domain,.ch-domain-container,.ch-domain-container-animation-wrapper{overflow:visible}.ch-domain-container.in-transition .ch-domain-container-animation-wrapper{overflow:hidden}.ch-domain-bg{fill:transparent}.ch-domain-text{fill:currentColor;font-size:10px}.ch-subdomain{overflow:visible}.ch-subdomain-bg{fill:#ededed}.ch-subdomain-bg.highlight{stroke:#444;stroke-width:1px}.ch-subdomain-bg:hover{stroke:#000;stroke-width:1px}.ch-subdomain-text{font-size:8px;pointer-events:none}[data-theme=dark] .ch-subdomain-bg{fill:#2d333b}[data-theme=dark] .ch-subdomain-bg.highlight{stroke:#768390}[data-theme=dark] .ch-subdomain-bg:hover{stroke:#636e7b}#ch-plugin-legend>svg{background:transparent;color:currentColor}#ch-tooltip{background:#222;border-radius:2px;box-shadow:2px 2px 2px rgba(0,0,0,.2);box-sizing:border-box;color:#bbb;display:none;font-size:12px;line-height:1.4;padding:5px 10px;text-align:center}#ch-tooltip[data-show]{display:block}#ch-tooltip-arrow,#ch-tooltip-arrow:before{background:inherit;height:8px;position:absolute;width:8px}#ch-tooltip-arrow{visibility:hidden}#ch-tooltip-arrow:before{content:"";transform:rotate(45deg);visibility:visible}#ch-tooltip[data-popper-placement^=top]>#ch-tooltip-arrow{bottom:-4px;margin-left:-4px}#ch-tooltip[data-popper-placement^=bottom]>#ch-tooltip-arrow{margin-left:-4px;top:-4px}#ch-tooltip[data-popper-placement^=left]>#ch-tooltip-arrow{right:-4px}#ch-tooltip[data-popper-placement^=right]>#ch-tooltip-arrow{left:-4px}#ch-tooltip[data-theme=dark]{background:#636e7b;color:#cdd9e5}
.SelectCard_card-border__FfiAK {
  background: linear-gradient(currentColor 0 0) 0 0, linear-gradient(currentColor 0 0) 0 0, linear-gradient(currentColor 0 0) 0 100%, linear-gradient(currentColor 0 0) 0 100%, linear-gradient(currentColor 0 0) 100% 0, linear-gradient(currentColor 0 0) 100% 0, linear-gradient(currentColor 0 0) 100% 100%, linear-gradient(currentColor 0 0) 100% 100%;
  background-size: var(--d, 0) 3px, 3px var(--d, 0);
  background-repeat: no-repeat;
  transition: background-size 0.25s, transform 0s;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.SelectCard_card-border__FfiAK:hover {
  --d: 20px;
}

.NotificationList_unread__OxKBI:hover {
  font-weight: 600;
}

.Block_in-hole__T0v60 {
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
  animation-name: Block_in-hole__T0v60;
}

@keyframes Block_in-hole__T0v60 {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(0);
  }
}

.Player_classic__2sJvu {
  color: transparent !important;
}
.Player_classic__2sJvu::before, .Player_classic__2sJvu::after {
  --block-size: 2em;
  --line-size: calc(var(--block-size) * 0.05);
  animation: 0.3s linear infinite Player_rotation__AWYK_;
  animation-play-state: inherit;
  content: "";
  position: absolute;
}
.Player_classic__2sJvu::before {
  /* before = lower layer = line spinner */
  --line-length: calc(var(--block-size) * 0.9); /* make line slightly larger than spinner */
  height: var(--line-size);
  width: var(--line-length);
  top: calc((var(--block-size) - var(--line-size)) / 2); /* vertically centered */
  left: calc((var(--block-size) - var(--line-length))/2); /* horizontally centered */
  background-color: var(--level-grid-used);
}
.Player_classic__2sJvu::after {
  /* after= upper layer = "circle" spinner, split into 24 segments for ease of path scaling */
  --circle-size-factor: 0.8;
  --diameter: calc(var(--block-size) * var(--circle-size-factor));
  --circle-offset: calc((var(--block-size) - var(--diameter))/2);
  --inner-circle-radius: 15%;
  height: var(--diameter);
  width: var(--diameter);
  top: var(--circle-offset);
  left: var(--circle-offset);
  border-radius: 50%; /* allows drawing lines to bounding box and clips them away to within the circle */
  /* gradient to make an inner "knob" with spokes and outer ring effect */
  background: radial-gradient(circle, #eee 5%, #ccc calc(var(--inner-circle-radius)*0.8), #555 calc(var(--inner-circle-radius)/5*7), #999 61%, #eee 66%, #999 71%);
  
  /* goneometry helper values */
  --sin0: calc(0);
  --sin15: calc(0.2588);
  --sin30: calc(0.5);
  --sin45: calc(1.4142 / 2);
  --sin60: calc(1.7321 / 2);
  --sin75: calc(0.9659);
  --sin90: calc(1);
  --cos0: var(--sin90);
  --cos15: var(--sin75);
  --cos30: var(--sin60);
  --cos45: var(--sin45);
  --cos60: var(--sin30);
  --cos75: var(--sin15);
  --cos90: var(--sin0);
  /* we're resorting to a polygon approximation over the generic SVG-path() option as the latter seems not easily scalable */
  clip-path: polygon(
    /* diagonal spokes */
    calc(50% - var(--line-size)/4*3) 50%,
    0% calc(0% + var(--line-size)/4*3),
    calc(0% + var(--line-size)/4*3) 0%,
    100% calc(100% - var(--line-size)/4*3),
    calc(100% - var(--line-size)/4*3) 100%,
    calc(50% - var(--line-size)/4*3) 50%,
    calc(100% - var(--line-size)/4*3) 0%,
    100% calc(0% + var(--line-size)/4*3),
    calc(0% + var(--line-size)/4*3) 100%,
    0% calc(100% - var(--line-size)/4*3),
    calc(50% - var(--line-size)/4*3) 50%,
    /* vertical spokes */
    calc(50% - var(--line-size)/2) calc(50% - var(--line-size)/2),
    calc(50% - var(--line-size)/2) 0%,
    calc(50% + var(--line-size)/2) 0%,
    calc(50% + var(--line-size)/2) 100%,
    calc(50% - var(--line-size)/2) 100%,
    /* NO horizontal spokes, the ::before line fills this role */
    /* outer ring */
    calc(50% - (var(--cos90) * 50%)) calc(50% + (var(--sin90) * 50%)),
    calc(50% - (var(--cos75) * 50%)) calc(50% + (var(--sin75) * 50%)),
    calc(50% - (var(--cos60) * 50%)) calc(50% + (var(--sin60) * 50%)),
    calc(50% - (var(--cos45) * 50%)) calc(50% + (var(--sin45) * 50%)),
    calc(50% - (var(--cos30) * 50%)) calc(50% + (var(--sin30) * 50%)),
    calc(50% - (var(--cos15) * 50%)) calc(50% + (var(--sin15) * 50%)),
    calc(50% - (var(--cos0) * 50%)) calc(50% + (var(--sin0) * 50%)),
    calc(50% - (var(--cos15) * 50%)) calc(50% - (var(--sin15) * 50%)),
    calc(50% - (var(--cos30) * 50%)) calc(50% - (var(--sin30) * 50%)),
    calc(50% - (var(--cos45) * 50%)) calc(50% - (var(--sin45) * 50%)),
    calc(50% - (var(--cos60) * 50%)) calc(50% - (var(--sin60) * 50%)),
    calc(50% - (var(--cos75) * 50%)) calc(50% - (var(--sin75) * 50%)),
    calc(50% - (var(--cos90) * 50%)) calc(50% - (var(--sin90) * 50%)),
    calc(50% + (var(--cos75) * 50%)) calc(50% - (var(--sin75) * 50%)),
    calc(50% + (var(--cos60) * 50%)) calc(50% - (var(--sin60) * 50%)),
    calc(50% + (var(--cos45) * 50%)) calc(50% - (var(--sin45) * 50%)),
    calc(50% + (var(--cos30) * 50%)) calc(50% - (var(--sin30) * 50%)),
    calc(50% + (var(--cos15) * 50%)) calc(50% - (var(--sin15) * 50%)),
    calc(50% + (var(--cos0) * 50%)) calc(50% + (var(--sin0) * 50%)),
    calc(50% + (var(--cos15) * 50%)) calc(50% + (var(--sin15) * 50%)),
    calc(50% + (var(--cos30) * 50%)) calc(50% + (var(--sin30) * 50%)),
    calc(50% + (var(--cos45) * 50%)) calc(50% + (var(--sin45) * 50%)),
    calc(50% + (var(--cos60) * 50%)) calc(50% + (var(--sin60) * 50%)),
    calc(50% + (var(--cos75) * 50%)) calc(50% + (var(--sin75) * 50%)),
    calc(50% - (var(--cos90) * 50%)) calc(50% + (var(--sin90) * 50%)),
    /* inner ring */
    calc(50% - (var(--cos90) * (50% - var(--line-size)))) calc(50% + (var(--sin90) * (50% - var(--line-size)))),
    calc(50% + (var(--cos75) * (50% - var(--line-size)))) calc(50% + (var(--sin75) * (50% - var(--line-size)))),
    calc(50% + (var(--cos60) * (50% - var(--line-size)))) calc(50% + (var(--sin60) * (50% - var(--line-size)))),
    calc(50% + (var(--cos45) * (50% - var(--line-size)))) calc(50% + (var(--sin45) * (50% - var(--line-size)))),
    calc(50% + (var(--cos30) * (50% - var(--line-size)))) calc(50% + (var(--sin30) * (50% - var(--line-size)))),
    calc(50% + (var(--cos15) * (50% - var(--line-size)))) calc(50% + (var(--sin15) * (50% - var(--line-size)))),
    calc(50% + (var(--cos0) * (50% - var(--line-size)))) calc(50% + (var(--sin0) * (50% - var(--line-size)))),
    calc(50% + (var(--cos15) * (50% - var(--line-size)))) calc(50% - (var(--sin15) * (50% - var(--line-size)))),
    calc(50% + (var(--cos30) * (50% - var(--line-size)))) calc(50% - (var(--sin30) * (50% - var(--line-size)))),
    calc(50% + (var(--cos45) * (50% - var(--line-size)))) calc(50% - (var(--sin45) * (50% - var(--line-size)))),
    calc(50% + (var(--cos60) * (50% - var(--line-size)))) calc(50% - (var(--sin60) * (50% - var(--line-size)))),
    calc(50% + (var(--cos75) * (50% - var(--line-size)))) calc(50% - (var(--sin75) * (50% - var(--line-size)))),
    calc(50% - (var(--cos90) * (50% - var(--line-size)))) calc(50% - (var(--sin90) * (50% - var(--line-size)))),
    calc(50% - (var(--cos75) * (50% - var(--line-size)))) calc(50% - (var(--sin75) * (50% - var(--line-size)))),
    calc(50% - (var(--cos60) * (50% - var(--line-size)))) calc(50% - (var(--sin60) * (50% - var(--line-size)))),
    calc(50% - (var(--cos45) * (50% - var(--line-size)))) calc(50% - (var(--sin45) * (50% - var(--line-size)))),
    calc(50% - (var(--cos30) * (50% - var(--line-size)))) calc(50% - (var(--sin30) * (50% - var(--line-size)))),
    calc(50% - (var(--cos15) * (50% - var(--line-size)))) calc(50% - (var(--sin15) * (50% - var(--line-size)))),
    calc(50% - (var(--cos0) * (50% - var(--line-size)))) calc(50% + (var(--sin0) * (50% - var(--line-size)))),
    calc(50% - (var(--cos15) * (50% - var(--line-size)))) calc(50% + (var(--sin15) * (50% - var(--line-size)))),
    calc(50% - (var(--cos30) * (50% - var(--line-size)))) calc(50% + (var(--sin30) * (50% - var(--line-size)))),
    calc(50% - (var(--cos45) * (50% - var(--line-size)))) calc(50% + (var(--sin45) * (50% - var(--line-size)))),
    calc(50% - (var(--cos60) * (50% - var(--line-size)))) calc(50% + (var(--sin60) * (50% - var(--line-size)))),
    calc(50% - (var(--cos75) * (50% - var(--line-size)))) calc(50% + (var(--sin75) * (50% - var(--line-size)))),
    calc(50% - (var(--cos90) * (50% - var(--line-size)))) calc(50% + (var(--sin90) * (50% - var(--line-size)))),
    /* keep line width whilst moving to inner circle */
    calc(50% - var(--line-size)/2) 100%,
    calc(50% - var(--line-size)/2) 50%,
    /* inner circle */
    calc(50% - (var(--cos90) * var(--inner-circle-radius))) calc(50% + (var(--sin90) * var(--inner-circle-radius))),
    calc(50% - (var(--cos75) * var(--inner-circle-radius))) calc(50% + (var(--sin75) * var(--inner-circle-radius))),
    calc(50% - (var(--cos60) * var(--inner-circle-radius))) calc(50% + (var(--sin60) * var(--inner-circle-radius))),
    calc(50% - (var(--cos45) * var(--inner-circle-radius))) calc(50% + (var(--sin45) * var(--inner-circle-radius))),
    calc(50% - (var(--cos30) * var(--inner-circle-radius))) calc(50% + (var(--sin30) * var(--inner-circle-radius))),
    calc(50% - (var(--cos15) * var(--inner-circle-radius))) calc(50% + (var(--sin15) * var(--inner-circle-radius))),
    calc(50% - (var(--cos0) * var(--inner-circle-radius))) calc(50% + (var(--sin0) * var(--inner-circle-radius))),
    calc(50% - (var(--cos15) * var(--inner-circle-radius))) calc(50% - (var(--sin15) * var(--inner-circle-radius))),
    calc(50% - (var(--cos30) * var(--inner-circle-radius))) calc(50% - (var(--sin30) * var(--inner-circle-radius))),
    calc(50% - (var(--cos45) * var(--inner-circle-radius))) calc(50% - (var(--sin45) * var(--inner-circle-radius))),
    calc(50% - (var(--cos60) * var(--inner-circle-radius))) calc(50% - (var(--sin60) * var(--inner-circle-radius))),
    calc(50% - (var(--cos75) * var(--inner-circle-radius))) calc(50% - (var(--sin75) * var(--inner-circle-radius))),
    calc(50% - (var(--cos90) * var(--inner-circle-radius))) calc(50% - (var(--sin90) * var(--inner-circle-radius))),
    calc(50% + (var(--cos75) * var(--inner-circle-radius))) calc(50% - (var(--sin75) * var(--inner-circle-radius))),
    calc(50% + (var(--cos60) * var(--inner-circle-radius))) calc(50% - (var(--sin60) * var(--inner-circle-radius))),
    calc(50% + (var(--cos45) * var(--inner-circle-radius))) calc(50% - (var(--sin45) * var(--inner-circle-radius))),
    calc(50% + (var(--cos30) * var(--inner-circle-radius))) calc(50% - (var(--sin30) * var(--inner-circle-radius))),
    calc(50% + (var(--cos15) * var(--inner-circle-radius))) calc(50% - (var(--sin15) * var(--inner-circle-radius))),
    calc(50% + (var(--cos0) * var(--inner-circle-radius))) calc(50% + (var(--sin0) * var(--inner-circle-radius))),
    calc(50% + (var(--cos15) * var(--inner-circle-radius))) calc(50% + (var(--sin15) * var(--inner-circle-radius))),
    calc(50% + (var(--cos30) * var(--inner-circle-radius))) calc(50% + (var(--sin30) * var(--inner-circle-radius))),
    calc(50% + (var(--cos45) * var(--inner-circle-radius))) calc(50% + (var(--sin45) * var(--inner-circle-radius))),
    calc(50% + (var(--cos60) * var(--inner-circle-radius))) calc(50% + (var(--sin60) * var(--inner-circle-radius))),
    calc(50% + (var(--cos75) * var(--inner-circle-radius))) calc(50% + (var(--sin75) * var(--inner-circle-radius))),
    calc(50% - (var(--cos90) * var(--inner-circle-radius))) calc(50% + (var(--sin90) * var(--inner-circle-radius))),
    /* back to center for a clean path closure with spokes */
    calc(50% - var(--line-size)/2) calc(50% + var(--line-size)/2)
  );
}

.Player_extra__FKAS_ {
  animation-duration: 0.1s;
  animation-fill-mode: forwards;
  animation-name: Player_extra-moves__KmHl7;
}

.Player_lose__BXnWd {
  animation-duration: 0.4s;
  animation-name: Player_wiggle__cDs2G;
}

.Player_win__Bu4R3 {
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-name: Player_spin__hxORl;
}

.Player_win-classic__AhJnQ {
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-name: Player_spin-classic__lCnF8;
}

@keyframes Player_extra-moves__KmHl7 {
  100% {
    background-color: var(--level-player-extra);
  }
}

@keyframes Player_spin__hxORl {
  20% {
    border-radius: 50%;
    transform: rotate(0deg) scale(1.3);
  }
  
  100% {
    background-color: var(--level-end);
    color: var(--level-grid-text);
    transform: rotate(360deg) scale(1);
  }
}

@keyframes Player_spin-classic__lCnF8 {
  100% {
    transform: rotate(1080deg);
  }
}

@keyframes Player_wiggle__cDs2G {
  0% {
    background-color: var(--level-player-extra);
  }

  20% {
    transform: rotate(0deg);
  }

  35% {
    transform: rotate(20deg);
  }

  55% {
    transform: rotate(-20deg);
  }

  80% {
    transform: rotate(5deg);
  }

  100% {
    background-color: var(--level-player-extra);
    transform: rotate(0deg);
  }
}

@keyframes Player_rotation__AWYK_ {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* devanagari */
@font-face {
  font-family: '__Teko_ca92a3';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/bd971bbe311c6fba-s.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+20F0, U+25CC, U+A830-A839, U+A8E0-A8FF, U+11B00-11B09;
}
/* latin-ext */
@font-face {
  font-family: '__Teko_ca92a3';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/d31b4caab900b838-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Teko_ca92a3';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/0a1ffadfeda9ba8f-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Teko_Fallback_ca92a3';src: local("Arial");ascent-override: 146.26%;descent-override: 72.52%;line-gap-override: 0.00%;size-adjust: 65.50%
}.__className_ca92a3 {font-family: '__Teko_ca92a3', '__Teko_Fallback_ca92a3';font-weight: 500;font-style: normal
}

